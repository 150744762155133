import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DefaultACTheme from "../../styles/DefaultACTheme";
import deLocale from "date-fns/locale/de";
import {connect, useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px',
    },
    question: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main
    }
}));

const DateInput = (props) => {
    const classes = useStyles();

    const value = useSelector(state => state[props.valueReduxKey]);
    const dispatch = useDispatch();

    function handleDateChanged(date) {
        //init vars
        let year = 2001;
        year = date.getFullYear();
        let month = 3;
        month = date.getMonth()+1; //getMonth() counts from 0 upwards - newDate() and our data-model doesnt -> add 1
        let day = 20;
        day = date.getDate();

        //add 0 to match format
        if(!!month && month < 10) {
            month = '0' + month;
        }
        if(!!day && day < 10) {
            day = '0' + day;
        }

        dispatch(updateValue(props.valueReduxKey, year + "-" + month + "-" + day));
    }

    return (
        <Container>
            <div className={classes.card}>
                <Row>
                    <Col>
                        <div className={(props.question_marked && !!!value) ? classes.question_marked : classes.question}>
                            {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                        </div>
                        {!!props.description && <div className={classes.description}>
                            {props.description}
                        </div>}
                    </Col>
                    <Col>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                            <KeyboardDatePicker
                                style={{marginTop: '-0px', marginBottom: "-0px"}}
                                disableToolbar
                                fullWidth
                                variant="dialog"
                                autoOk
                                inputVariant="outlined"
                                format="dd.MM.yyyy"
                                margin="normal"
                                value={!!value ? new Date(value) : new Date('2001-03-20')}
                                onChange={handleDateChanged}
                                invalidLabel={"Unbekanntes Format!"}
                                openTo={"year"}
                                disableFuture={"true"}
                                maxDateMessage={"Invalides Datum!"}
                                minDateMessage={"Invalides Datum!"}
                                invalidDateMessage={"Invalides Datum - bitte ändere dies!"}
                            />
                        </MuiPickersUtilsProvider>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};

DateInput.propTypes = {
    question: PropTypes.string,
    key: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    question_marked: PropTypes.bool
};

export default DateInput;