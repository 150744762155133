import {createMuiTheme} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#993333',
        },
        secondary: {
            main: '#878787',
            light: '#E6E6E6',
            dark: '#4F4F4F',
        }
    },
});

export default theme;