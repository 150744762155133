import React, {Component} from "react";
import HeaderBar from "../components/HeaderBar";
import ProgressStepper from "../components/ProgressStepper";
import BigTextInput from "../components/Input/BigTextInput";
import ContinueButton from "../components/ContinueButton";
import store from "../components/reduxStore/store";
import SiteQuestions from "../components/SiteQuestions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "@material-ui/core/Container";
import DefaultACTheme from "../styles/DefaultACTheme";
import SmallTextInput from "../components/Input/SmallTextInput";
import BackwardButton from "../components/BackwardsButton";

class LaufbahnPage extends Component {

    componentDidMount() {
        const reduxStore = store.getState();
        //check if the data is already loaded and user has an account -> redirect to landingpage
        if (!reduxStore.dataFetchedByAPI) {
            // consolelog("redirecting to landingpage for auth...")
            this.props.history.push("/s/-1");
        }
    }

    render() {
        const reduxStore = store.getState();

        const mark_incomplete_questions = reduxStore["mark_incomplete_questions"];

        const questions = reduxStore["motivation_questions"];
        // consolelog(questions);
        let questionsOrdered = new Map();
        let questionsDescriptions = new Map();
        questions.map(quest => {
            if (questionsOrdered.has(quest.group.name)) {
                let oldArray = questionsOrdered.get(quest.group.name);
                oldArray.push(quest);
                questionsOrdered.set(quest.group.name, oldArray);
            } else {
                const newArray = [];
                newArray.push(quest);
                questionsOrdered.set(quest.group.name, newArray);
            }

            questionsDescriptions.set(quest.group.name, quest.group.description);
        });

        return (
            <div className={"LaufbahnPage"}>
                <HeaderBar/>
                <ProgressStepper activeState={2}/>

                {Array.from(questionsOrdered.keys()).map(group =>
                    <Container>
                        <div style={{marginTop: 20, marginBottom: 5}}>
                            <div style={{fontSize: 23, lineHeight: 1.1, fontWeight: "bolder", color: DefaultACTheme.palette.primary.main}}>
                                {group}
                            </div>
                            {questionsDescriptions.get(group) && <div style={{color: DefaultACTheme.palette.secondary.main}}>{ questionsDescriptions.get(group) }</div>}
                        </div>
                        {questionsOrdered.get(group).map(quest => {
                            return <div style={{marginBottom: 15}}>
                                <BigTextInput valueReduxKey={"motivation_answer_" + quest.id} required={true}
                                              description={!!quest.applicant_description ? quest.applicant_description : null}
                                              question={quest.applicant_question} rows={3} wordLimit={quest.word_limit}
                                              question_marked={mark_incomplete_questions}/>
                            </div>
                        })}
                    </Container>
                )}

                <SiteQuestions site_id={3}/>

                <ContinueButton linkTo={"/furtherquestions"}/>
                <BackwardButton linkTo={"/studydata"}/>
            </div>
        );
    }
}

export default LaufbahnPage;