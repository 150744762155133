import React from "react";
import Col from "react-bootstrap/Col";
import LinearProgress from '@material-ui/core/LinearProgress';
import AC_Logo from "../assets/logo.png";
import PropTypes from "prop-types";
import DefaultACTheme from "../styles/DefaultACTheme";
import Row from "react-bootstrap/Row";

const LoadingWheel = (props) => {
    return (
        <div>
            <Col md={{span: 6}}>
                <Row className={'justify-content-center'}>
                    <img src={AC_Logo} height={'50%'} width={'50%'} style={{marginTop: '2rem', marginBottom: '2rem'}}/>

                    {props.error ? <span style={{color: DefaultACTheme.palette.primary.main}}>
                        Fehler beim Laden
                    </span> : <span style={{color: DefaultACTheme.palette.primary.main}}>
                        Lade Daten - bitte erlaube bis zu 20 Sekunden
                    </span>}

                </Row>
                <LinearProgress variant={"indeterminate"} color={props.error ? "secondary" : "primary"}/>

            </Col>
        </div>
    );
};

LoadingWheel.propTypes = {
    error: PropTypes.bool
};

export default LoadingWheel;