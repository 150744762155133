import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';
import {useDispatch} from "react-redux";
import updateValue from "../../reduxActions/updateValue";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props}/>;
}

function WelcomeBackPopup() {
    const [open, setOpen] = React.useState(true);
    const dispatch = useDispatch();

    return (
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            open={open}
            autoHideDuration={5000}
            onClick={() => { setOpen(false)}}>
            <Alert onClose={() => { setOpen(false); dispatch(updateValue("showUserAlreadyExistsInfo", false))}} severity={"info"}>
                Willkommen zurück! Wir freuen uns auf deine Bewerbung!
            </Alert>
        </Snackbar>
    );

}

export default WelcomeBackPopup;