import React, {useState} from "react";
import Divider from "@material-ui/core/Divider";
import {useDispatch} from "react-redux";
import store from "./reduxStore/store";
import PropTypes from "prop-types";
import SmallTextInput from "./Input/SmallTextInput";
import DropdownInput from "./Input/DropdownInput";
import BigTextInput from "./Input/BigTextInput";
import palette from "../styles/DefaultACTheme";
import SelectInput from "./Input/SelectInput";
import CheckboxInput from "./Input/CheckboxInput";

const SiteQuestions = (props) => {

    const reduxStore = store.getState();
    const dispatch = useDispatch();

    const mark_incomplete_questios = reduxStore["mark_incomplete_questions"];

    const questions = reduxStore.questions[props.site_id];

    let isShowing = false;


    //Checking if there are questions
    if (!!!questions || questions.length === 0) {
        isShowing = false;
    } else {
        isShowing = true;
    }

    return (
        <div>
            {isShowing &&
            <div>
                {!props.hideDivider &&
                    <>
                        <Divider style={{marginTop: '5%', marginBottom: '10px'}}/>
                        <div style={{paddingBottom: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '25px',
                            color: palette.palette.primary.main,
                            fontWeight: 'bold',
                            textAlign: 'center'}}>
                            Weitere Fragen
                        </div>
                    </>
                }
                {questions.map(question => {
                    let id = question.id;
                    let quest = question.question;
                    let desc = question.description;
                    let required = question.is_required;
                    let val = [];

                    //calculate/unpack values - it is:  "values": [{"value": "testValue", "description": null}]
                    if(!!question["values"] && question["values"].length > 0) {
                        question["values"].map(value => {
                            val.push(value["value"]);
                        })
                    }

                    switch(question.type) {
                        case "text": return <SmallTextInput question={quest} description={desc} required={required}
                                                            valueReduxKey={"questionsAnswers." + id} key={id} question_marked={mark_incomplete_questios && required}/>; break;
                        case "dropdown": return <DropdownInput question={quest} description={desc} required={required}
                                                               valueReduxKey={"questionsAnswers." + id} options={val}
                                                               optionsMapping={val} key={id} question_marked={mark_incomplete_questios && required}/>; break;
                        case "textarea": return <BigTextInput question={quest} description={desc} required={required}
                                                              valueReduxKey={"questionsAnswers." + id} rows={4} key={id} question_marked={mark_incomplete_questios && required}/>; break;
                        case "checkbox": return <SelectInput question={quest} description={desc} required={required}
                                                            valueReduxKey={"questionsAnswers." + id} options={val} key={id} question_marked={mark_incomplete_questios && required}/>; break;
                    }
                })}
            </div>
            }

        </div>

    )
};

SiteQuestions.propTypes = {
    site_id: PropTypes.number,
}

export default SiteQuestions;