import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props}/>;
}

function SomethingWentWrongPopup() {
    const [open, setOpen] = React.useState(true);

    return (
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            open={open}
            autoHideDuration={5000}
            onClick={() => { setOpen(false)}}>
            <Alert onClose={() => { setOpen(false)}} severity={"warning"}>
                Oops! Etwas ist schief gelaufen!
            </Alert>
        </Snackbar>
    );

}

export default SomethingWentWrongPopup;