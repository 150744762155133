import React, {Component} from "react";
import LoadingWheel from "../components/LoadingWheel";

class WelcomePage extends Component {

    render() {
        return (
            <div className={"WelcomePage"}>
                <LoadingWheel/>
                redirecting...
                {this.props.history.push("/s/-1")}
            </div>
        );
    }
}

export default WelcomePage;