import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import WelcomePage from "./routes/WelcomePage";
import PersonalDataPage from "./routes/PersonalDataPage";
import StudyDataPage from "./routes/StudyDataPage";
import LaufbahnPage from "./routes/LaufbahnPage";
import FurtherQuestionsPage from "./routes/FurtherQuestionsPage";
import {ThemeProvider} from "@material-ui/core/styles";
import DefaultACTheme from "./styles/DefaultACTheme";
import LandingPage from "./routes/LandingPage";
import OverviewPage from "./routes/OverviewPage";
import UmfragePage from "./routes/UmfragePage";
import EditPage from "./routes/EditPage";

function App() {
  return (
      <ThemeProvider theme={DefaultACTheme}>
          <Router>
              <div>
                  <Switch>
                      <Route exact path={"/personaldata"} component={PersonalDataPage}/>
                      <Route exact path={"/studydata"} component={StudyDataPage}/>
                      <Route exact path={"/laufbahn"} component={LaufbahnPage}/>
                      <Route exact path={"/furtherquestions"} component={FurtherQuestionsPage}/>
                      <Route exact path={"/umfrage"} component={UmfragePage}/>
                      <Route path={"/overview"} component={OverviewPage}/>
                      <Route path={"/s"} component={LandingPage}/>
                      <Route path={"/edit"} component={EditPage}/>
                      <Route path={"/"} component={WelcomePage}/>

                  </Switch>
              </div>
          </Router>
      </ThemeProvider>
  );
}

export default App;
