import React, {useState} from "react";
import Fab from "@material-ui/core/Fab";
import DoneIcon from '@material-ui/icons/Done';
import makeStyles from "@material-ui/core/styles/makeStyles";
import store from "./reduxStore/store";
import MissingInfoContentpane from "./MissingInfoContentpane";
import Backdrop from "@material-ui/core/Backdrop";
import SomethingWentWrongPopup from "./Snackbars/SomethingWentWrongPopup";
import API from "../utils/API";
import {useDispatch} from "react-redux";
import updateValue from "../reduxActions/updateValue";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const useStyles = makeStyles(theme => ({
    card: {
        position: "fixed",
        bottom: "20px",
        right: "20px"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const FinishButton = (props) => {
    const classes = useStyles();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const dispatch = useDispatch();

    async function handleClick() {
        setLoading(true);
        const reduxState = store.getState();

        try { //try-catch in order to show error
            //save interview-info
            let interviewsArray = [];
            reduxState.interview_dates.map(date => {
                if(reduxState["interview_" + date.id]) {
                    interviewsArray.push(date.id);
                }
            });

            let paramsInterviews = {
                interview_date_ids: interviewsArray
            };

            //setting complete-state
            if(!!interviewsArray && interviewsArray.length > 0) {
                dispatch(updateValue("furtherquestions_filled", true));
            } else {
                dispatch(updateValue("furtherquestions_filled", false));
            }

            //saving sitequestions
            let questions = reduxState.questions[5];
            let questions_complete = true;
            if(!!questions && questions.length > 0) {
                questions.map(quest => {
                    let answer = reduxState["questionsAnswers." + quest.id];

                    // consolelog(quest.id, answer, !!answer);

                    if(!!answer) {
                        //checking if answer is already in an array
                        try {
                            if(answer instanceof Array) {
                                let addition = {values: answer}
                                API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                            } else {
                                let answerPort = [];
                                answerPort.push(answer);
                                let addition = {
                                    values: answerPort
                                };
                                API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                            }
                        } catch (e) {
                            let answerPort = [];
                            answerPort.push(answer);
                            let addition = {
                                values: answerPort
                            };
                            API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                        }
                    } else {
                        if(quest.is_required) {
                            questions_complete = false;
                        }
                    }
                })
            }

            if(questions_complete) {
                dispatch(updateValue("questions5_filled", true));
            } else {
                dispatch(updateValue("questions5_filled", false));
            }

            await API.post("applicant/" + reduxState.applicant_id + "/interview_dates", paramsInterviews);

            setBackdropOpen(true);
            setLoading(false);
        } catch (e) {
            setError(true);
        }
    }

    return (
        <div>
            {error && <SomethingWentWrongPopup/>}
            <Fab className={classes.card} color={"primary"} variant={"extended"} onClick={handleClick}>
                {loading ?
                <div>
                    Speichern...
                    <CircularProgress variant={"indeterminate"} color={"#FFFFFF"} size={18}/>
                </div> :
                <div>
                    Abschließen
                    <DoneIcon/>
                </div>}
            </Fab>
            <Backdrop className={classes.backdrop} open={backdropOpen} onClick={() => setBackdropOpen(false)}>
                <MissingInfoContentpane/>
            </Backdrop>
        </div>

    )
};

export default FinishButton;