import React, {Component} from "react";
import HeaderBar from "../components/HeaderBar";
import ProgressStepper from "../components/ProgressStepper";
import FinishButton from "../components/FinishButton";
import {connect} from "react-redux";
import updateValue from "../reduxActions/updateValue";
import store from "../components/reduxStore/store";
import SiteQuestions from "../components/SiteQuestions";
import BackwardButton from "../components/BackwardsButton";

class UmfragePage extends Component {

    async componentWillMount() {
        const reduxStore = store.getState();
        //check if the data is already loaded and user has an account -> redirect to landingpage
        if (!reduxStore.dataFetchedByAPI) {
            // consolelog("redirecting to landingpage for auth...")
            this.props.history.push("/s/-1");
        }
    };

    render() {
        return (
            <div className={"SurveyPage"}>
                <HeaderBar/>
                <ProgressStepper activeState={4}/>

                <SiteQuestions site_id={5} hideDivider={true} />

                <BackwardButton linkTo={"/furtherquestions"}/>

                <FinishButton/>
            </div>
        );
    }
}

const mapReduxStateToProps = (state) => {
    return {
        applicant_id: state.applicant_id,
        interview_dates: state.interview_dates,
        mark_incomplete_questions: state.mark_incomplete_questions
    };
};

const mapReduxDispatchToProps = (dispatch, ownProps) => {
    return {
        updateValue
    };
};

export default connect(mapReduxStateToProps, mapReduxDispatchToProps)(UmfragePage);