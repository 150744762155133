const initState = {
    //basic-infos
    applicant_key: localStorage.getItem("applicant_key") || '',
    applicant_id: localStorage.getItem("applicant_id") || -1,
    dataFetchedByAPI: false,
    application_complete: false,
    redirect_to_overview: false,
    mark_incomplete_questions: false,
    //Personal-Infos
    first_name: null,
    last_name: null,
    birthday: null,
    email: null,
    phone: null,
    gender: null,
    picture: null,
    picture_changed: false,
    cv: null,
    cv_changed: false,
    personal_filled: false,
    //Study-Infos
    university_id: null,
    semester: null,
    remaining_semester: null,
    branch_of_study_id: null,
    course_of_studies: null,
    degree_id: null,
    number: null,
    letter_of_immatriculation: null,
    letter_of_immatriculaton_changed: false,
    study_filled: false,
    //Laufbahn-Infos
    motivation: null, //unused since 06.07.2020
    motivation_questions: [],
    laufbahn_filled: false,
    //FurtherQuestions-Infos
    interview_dates: null,
    furtherquestions_filled: false,
    //sitequestions
    questions: [],
    questionsAnswers: null,
    questions1_filled: false,
    questions2_filled: false,
    questions3_filled: false,
    questions4_filled: false,

};

const rootReducer = (state = initState, action) => {
    if (action.type === 'UPDATE_VALUE') {
        // consolelog(action.key + " - " + action.value);
        return {
            ...state,
            [action.key]: action.value
        };
    }
    return state;
};

export default rootReducer;