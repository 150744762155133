import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";
import DefaultACTheme from "../../styles/DefaultACTheme";
import Button from "@material-ui/core/Button";
import Col from "react-bootstrap/Col";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px'
    },
    question: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main
    }
}));

const FileInput = (props) => {
    const classes = useStyles();

    const file = useSelector(state => state[props.valueReduxKey]);
    const dispatch = useDispatch();

    function handleFileChange(file) {
        dispatch(updateValue(props.valueReduxKey, file));
        dispatch(updateValue(props.valueReduxKey + "_changed", true));
    }

    function buildFileSelector() {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', '.pdf');
        fileSelector.setAttribute('onChange', function () {
            handleFileUpload(fileSelector.files)
        });
        fileSelector.onchange = function () {
            handleFileUpload(fileSelector.files)
        };

        return fileSelector;
    }

    function openFileSelector() {
        let fileSelector = buildFileSelector();
        fileSelector.click();
    }

    function handleFileUpload(files) {
        handleFileChange(files[0]);
    }

    const fileName = () => {
        if(!!file.name) {
            return file.name;
        } else {
            return "Eine Datei ";
        }
    }

    return (
        <Container>
            <div className={classes.card}>
                <Row>
                    <Col>
                        <div className={(props.question_marked && !!!file) ? classes.question_marked : classes.question}>
                            {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                        </div>
                        {!!props.description && <div className={classes.description}>
                            {props.description}
                        </div>}
                    </Col>
                    <Col md={{offset: 0}}>
                        {!!!file &&
                        <Button variant={"outlined"} color={"secondary"} onClick={openFileSelector} fullWidth>Jetzt Datei hochladen!</Button>
                        }
                        {!!file && <div>
                            <Button variant={"outlined"} color={"secondary"}
                                    onClick={openFileSelector} fullWidth>Neue Datei hochladen</Button>
                        </div>}
                        <span className={classes.description}>
                            Aktuell: {!!file ? fileName() : "keine Datei"} hochgeladen
                        </span>
                    </Col>
                </Row>

            </div>
        </Container>
    )
};

FileInput.propTypes = {
    question: PropTypes.string,
    key: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    question_marked: PropTypes.bool
};

export default FileInput;