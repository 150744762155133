import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";
import DefaultACTheme from "../../styles/DefaultACTheme";
import Col from "react-bootstrap/Col";
import social from "../../assets/social.svg";
import Avatar from "@material-ui/core/Avatar";
import AC_Logo from "../../assets/logo.png";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px'
    },
    question: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main
    }
}));

const PictureInput = (props) => {
    const classes = useStyles();

    const file = useSelector(state => state[props.valueReduxKey]);
    const dispatch = useDispatch();

    function handleFileChange(file) {
        dispatch(updateValue(props.valueReduxKey, file));
        dispatch(updateValue(props.valueReduxKey + "_changed", true));
    }

    function deleteFile() {
        dispatch(updateValue(props.valueReduxKey, null));
    }

    function buildFileSelector() {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', '.jpg,.jpeg,.gif,.png,.svg');
        fileSelector.setAttribute('onChange', function () {
            handleFileUpload(fileSelector.files)
        });
        fileSelector.onchange = function () {
            handleFileUpload(fileSelector.files)
        };

        return fileSelector;
    }

    function openFileSelector() {
        let fileSelector = buildFileSelector();
        fileSelector.click();
    }

    function handleFileUpload(files) {
        handleFileChange(files[0]);
    }

    const avatar = () => {
        if(!!file) {
            try {
                return (
                    <Avatar src={URL.createObjectURL(file)} onClick={openFileSelector} variant={"rounded"}/>
                );
            } catch (e) {
                return (
                    <Avatar src={AC_Logo} onClick={openFileSelector} variant={"rounded"}/>
                );
            }
        } else {
            return (
                <Avatar src={social} onClick={openFileSelector} variant={"rounded"}/>
            );
        }

    }

    return (
        <Container>
            <div className={classes.card}>
                <Row>
                    <Col>
                        <div className={(props.question_marked && !!!file) ? classes.question_marked : classes.question}>
                            {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                        </div>
                        {!!props.description && <div className={classes.description}>
                            {props.description}
                        </div>}
                    </Col>
                    <Col md={{offset: 0}}>
                        {avatar()}
                    </Col>
                </Row>

            </div>
        </Container>
    )
};

PictureInput.propTypes = {
    question: PropTypes.string,
    key: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    question_marked: PropTypes.bool
};

export default PictureInput;