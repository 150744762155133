import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import Checkbox from "@material-ui/core/Checkbox";
import DefaultACTheme from "../../styles/DefaultACTheme";
import {useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px',
    },
    question: {
        marginTop: '0.4rem',
        fontSize: '18px',
        fontWeight: 'bold'
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main
    }
}));

const SelectInput = (props) => {
    const classes = useStyles();

    let value = useSelector(state => state[props.valueReduxKey]);

    if(!!!value) {
        value = [];
    }

    const dispatch = useDispatch();

    function handleValueChanged(valueOfOption) {
        const option = valueOfOption.target.name;
        let newValue = [];
        value.map(old => {
            if (old === option) {
                //remove first
            } else {
                newValue.push(old);
            }
        });
        if (valueOfOption.target.checked) {
            newValue.push(option);
        }
        dispatch(updateValue(props.valueReduxKey, newValue));
    }

    function isChecked(option) {
        return (!!!value || value.length < 1) ? false : value.includes(option);
    }

    return (
        <Container>
            <div className={classes.card}>
                <Row>
                    <Col>
                        <div className={(props.question_marked && !!!value) ? classes.question_marked : classes.question}>
                            {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                        </div>
                        {!!props.description && <div className={classes.description}>
                            {props.description}
                        </div>}
                    </Col>
                    <Col>
                        {props.options.map(option => (
                            <Row>
                                <Checkbox name={option} color={"primary"} onChange={handleValueChanged} defaultChecked={isChecked(option)}/>
                                <div className={classes.question}>
                                    {option}
                                </div>
                            </Row>
                        ))}
                    </Col>

                </Row>
            </div>
        </Container>
    )
};

SelectInput.propTypes = {
    question: PropTypes.string,
    key: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    options: PropTypes.array,
    question_marked: PropTypes.bool
};

export default SelectInput;