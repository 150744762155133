import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import {useHistory} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import palette from "../styles/DefaultACTheme";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const useStyles = makeStyles(theme => ({
    title: {
        paddingBottom: '40px',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '25px',
        color: palette.palette.primary.main,
        fontWeight: 'bold',
        textAlign: 'center'
    }
}));

let getTitle = (id) => {
    switch (id) {
        case 0:
            return 'Persönliche Daten';
        case 1:
            return 'Angaben zum Studium';
        case 2:
            return 'Deine Bewerbung';
        case 3:
            return 'Weiterer Ablauf';
        case 4:
            return 'Abschließende Fragen';
        default:
            return 'Academy Consult';
    }
};

const ProgressStepper = (props) => {
    const classes = useStyles();
    let history = useHistory();

    return (
        <div>
            <Stepper alternativeLabel={true} activeStep={props.activeState}>
                <Step key={1}>
                    <StepLabel>{getTitle(0)}</StepLabel>
                </Step>
                <Step key={2}>
                    <StepLabel>{getTitle(1)}</StepLabel>
                </Step>
                <Step key={3}>
                    <StepLabel>{getTitle(2)}</StepLabel>
                </Step>
                <Step key={4}>
                    <StepLabel>{getTitle(3)}</StepLabel>
                </Step>
                <Step key={5}>
                    <StepLabel>{getTitle(4)}</StepLabel>
                </Step>
            </Stepper>
            <Row>
                <Col className={classes.title} md={{span: 3, offset: 4}}>
                    <div>
                        {getTitle(props.activeState)}
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default ProgressStepper;