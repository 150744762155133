import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import Checkbox from "@material-ui/core/Checkbox";
import DefaultACTheme from "../../styles/DefaultACTheme";
import {useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px',
    },
    question: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main
    }
}));

const CheckboxInput = (props) => {
    const classes = useStyles();

    const value = useSelector(state => state[props.valueReduxKey]);
    const dispatch = useDispatch();

    function handleValueChanged(value) {
        dispatch(updateValue(props.valueReduxKey, value.target.checked));
    }

    return (
        <Container>
            <div className={classes.card}>
                <Row>
                    <Col>
                        <div className={(props.question_marked && !!!value) ? classes.question_marked : classes.question}>
                            {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                        </div>
                        {!!props.description && <div className={classes.description}>
                            {props.description}
                        </div>}
                    </Col>
                    <Col>
                        <Checkbox color={"primary"} onChange={handleValueChanged} defaultChecked={!!value ? value : false}/>
                    </Col>

                </Row>
            </div>
        </Container>
    )
};

CheckboxInput.propTypes = {
    question: PropTypes.string,
    key: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    question_marked: PropTypes.bool
};

export default CheckboxInput;