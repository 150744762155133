import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import DefaultACTheme from "../../styles/DefaultACTheme";
import {useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px',
    },
    question: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main,
        marginBottom: '10px'
    }
}));

const BigTextInput = (props) => {
    const classes = useStyles();

    const value = useSelector(state => state[props.valueReduxKey]);
    const dispatch = useDispatch();

    function handleValueChange(e) {
        dispatch(updateValue(props.valueReduxKey, e.target.value));
    }

    let tooMuchWords = false;

    function wordLimit() {
        let current = 0;
        if (!!value) {
            current = value.trim(" ").split(" ").length;
            if(current >= props.wordLimit*0.8) {
                tooMuchWords = true;
            } else {
                tooMuchWords = false;
            }
        }

        return "(" + current + "/" + props.wordLimit + " Wörtern)";
    }

    return (
        <Container>
            <div className={classes.card}>

                <div>
                    <div className={(props.question_marked && !!!value) ? classes.question_marked : classes.question}>
                        {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                    </div>
                    {(!!props.description || !!props.wordLimit) && <div className={classes.description}>
                        {!!props.description && props.description}
                        <span> {!!props.wordLimit && wordLimit()}</span>
                    </div>}
                </div>
                <div>
                    <TextField multiline fullWidth rows={(props.rows > 0 && props.rows) || 15} label={"Deine Antwort"}
                               defaultValue={!!value ? value : null} variant={"outlined"}
                               placeholder={props.placeholderValue} id={props.key}
                               onChange={handleValueChange} error={tooMuchWords}/>

                </div>
            </div>
        </Container>
    )
};

BigTextInput.propTypes = {
    question: PropTypes.string,
    placeholderValue: PropTypes.string,
    key: PropTypes.string,
    rows: PropTypes.number,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    wordLimit: PropTypes.number,
    question_marked: PropTypes.bool
};

export default BigTextInput;