import React, {Component} from "react";
import LoadingWheel from "../components/LoadingWheel";

class EditPage extends Component {

    componentDidMount() {
        let paths = this.props.history.location.pathname.split('/');

        window.localStorage.setItem('applicant_id', paths[2]);
        window.localStorage.setItem('applicant_key', paths[3]);

        window.location = '/personaldata';
    }

    render() {
        return (
            <div className={"text-center my-5"}>
                <LoadingWheel/>
                Bewerbung wird geladen
            </div>
        );
    }
}

export default EditPage;