import React from "react";
import store from "./reduxStore/store";
import {useDispatch} from "react-redux";
import Row from "react-bootstrap/Row";
import Card from "@material-ui/core/Card";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Col from "react-bootstrap/Col";
import Chip from "@material-ui/core/Chip";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from "@material-ui/core/Button";
import {useHistory} from "react-router-dom";
import API from "../utils/API";
import updateValue from "../reduxActions/updateValue";
import DefaultACTheme from "../styles/DefaultACTheme";

const useStyles = makeStyles(theme => ({
    background: {
        opacity: '1.0',
        borderRadius: '25px'
    },
    card: {
        margin: '5%',
        flexGrow: 1,
        height: '50%',
        minHeight: '90%',
        backgroundColor: theme.palette.secondary.light
    }
}));

export default function MissingInfoContentpane(props) {
    const classes = useStyles();
    const reduxState = store.getState();
    const dispatch = useDispatch();
    let history = useHistory();

    const PageCard = (props) => {
        let staticQuestionsDone = false;
        let staticColor;
        let dynamicQuestonsDone = false;
        let dynamicColor;
        let linkToSide = "/personaldata";

        let sitequestions = false;

        switch (props.title) {
            case("Persönliche Informationen"): {
                linkToSide = "/personaldata";
                staticQuestionsDone = reduxState.personal_filled;
                dynamicQuestonsDone = reduxState.questions1_filled;
                if(!!reduxState.questions[1] && reduxState.questions[1].length > 0) {
                    sitequestions = true;
                }
                break;
            }
            case("Angaben zum Studium"): {
                linkToSide = "/studydata";
                staticQuestionsDone = reduxState.study_filled;
                dynamicQuestonsDone = reduxState.questions2_filled;
                if(!!reduxState.questions[2] && reduxState.questions[2].length > 0) {
                    sitequestions = true;
                }
                break;
            }
            case("Deine Bewerbung"): {
                linkToSide = "/laufbahn";
                staticQuestionsDone = reduxState.laufbahn_filled;
                dynamicQuestonsDone = reduxState.questions3_filled;
                if(!!reduxState.questions[3] && reduxState.questions[3].length > 0) {
                    sitequestions = true;
                }
                break;
            }
            case("Weiterer Ablauf"): {
                linkToSide = "/furtherquestions";
                staticQuestionsDone = reduxState.furtherquestions_filled;
                dynamicQuestonsDone = reduxState.questions4_filled;
                if(!!reduxState.questions[4] && reduxState.questions[4].length > 0) {
                    sitequestions = true;
                }
                break;
            }
            case("Abschließende Fragen"): {
                linkToSide = "/umfrage";
                staticQuestionsDone = true;
                dynamicQuestonsDone = reduxState.questions5_filled;
                if(!!reduxState.questions[5] && reduxState.questions[5].length > 0) {
                    sitequestions = true;
                }
                break;
            }
            default:
                break;
        }

        return (
            <Card className={classes.card}>
                <CardContent>
                    <div style={{marginLeft: '5px'}}>
                        <div style={{fontSize: '20px', fontWeight: 'bold', height: '30%'}}>
                            {props.title}
                        </div>
                        {linkToSide !== "/umfrage" &&
                            <div style={{marginTop: '10%'}}>
                                <Chip icon={staticQuestionsDone ? <DoneIcon/> :
                                    <ErrorOutlineIcon color={DefaultACTheme.palette.primary.main}/>}
                                      label={"Pflichtangaben"} variant={"outlined"} fullWidth
                                      color={staticQuestionsDone ? "secondary" : "primary"}/>
                            </div>
                        }
                        {sitequestions ?
                            <div style={{marginTop: '5px'}}>
                                <Chip icon={dynamicQuestonsDone ? <DoneIcon/> : <ErrorOutlineIcon color={DefaultACTheme.palette.primary.main}/>}
                                      label={"Weitere Fragen"} variant={"outlined"} fullWidth color={dynamicQuestonsDone ? "secondary" : "primary"}/>
                            </div>
                            :
                            <div style={{marginTop: '5px'}}>
                                <Chip label={""} fullWidth disabled style={{backgroundColor: DefaultACTheme.palette.secondary.light} }/>
                            </div>
                        }
                    </div>
                </CardContent>
                <CardActions>
                    <div style={{marginTop: '15%', marginLeft: '5px'}}>
                        <Button variant={"text"} color={"primary"} onClick={() => {
                            dispatch(updateValue("mark_incomplete_questions", true));
                            history.push(linkToSide)
                            }
                        }>Seite
                            ansehen</Button>
                    </div>
                </CardActions>

            </Card>
        );
    };

    let ableToFinish = false; //update when side-questions come in
    if(reduxState.personal_filled && reduxState.study_filled && reduxState.laufbahn_filled && reduxState.furtherquestions_filled &&
        reduxState.questions1_filled && reduxState.questions2_filled && reduxState.questions3_filled && reduxState.questions4_filled) {
        ableToFinish = true;
    }
    let finishButtonColor = ableToFinish ? '#39e600' : '#999999';
    let isFinished = reduxState.application_complete;

    async function finishApplication() {
        await API.post("applicant/" + reduxState.applicant_id + "/complete");
        dispatch(updateValue("application_complete", true));
        history.push("/overview");
    }

    return (
        <div className={classes.background}>
            <div style={{fontSize: '25px', marginTop: '10px', marginLeft: '10px'}}>
                Hey {!!reduxState.first_name ? reduxState.first_name : "Bewerber"},
            </div>
            <div style={{fontSize: '15px', marginLeft: '10px', marginBottom: '20px'}}>
                hier siehst Du den Status deiner Bewerbungsinformationen:
            </div>
            <div style={{fontSize: '20px', marginLeft: '10px', marginBottom: '20px', fontWeight: 'bold'}}>
                {reduxState.application_complete ? <DoneIcon style={{color: '#39e600'}}/> : <ErrorOutlineIcon style={{color: '#ff3333'}}/>}
                {reduxState.application_complete ? "Wir haben deine Bewerbung erhalten!" : "Deine Bewerbung ist noch privat!"}
            </div>
            <Row style={{justifyContent: 'space-evenly'}}>
                <Col>
                    <PageCard title={"Persönliche Informationen"} fullWidth/>
                </Col>
                <Col>
                    <PageCard title={"Angaben zum Studium"} fullWidth/>
                </Col>
                <Col>
                    <PageCard title={"Deine Bewerbung"} fullWidth/>
                </Col>
                <Col>
                    <PageCard title={"Weiterer Ablauf"} fullWidth/>
                </Col>
                <Col>
                    <PageCard title={"Abschließende Fragen"} fullWidth/>
                </Col>
            </Row>
            <Row style={{justifyContent: 'center'}}>
                {!isFinished && <Button variant={"outlined"} style={{minWidth: '50%', backgroundColor: finishButtonColor, fontWeight: 'bold', marginBottom: '10px'}} size={"large"} onClick={ableToFinish ? finishApplication : null}>
                    {ableToFinish ? "Bewerbung freigeben!" : "Fülle alle Informationen aus, um die Bewerbung abzuschließen"}
                </Button>}
            </Row>
        </div>
    );
};