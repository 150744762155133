import React, {Component} from "react";
import HeaderBar from "../components/HeaderBar";
import ProgressStepper from "../components/ProgressStepper";
import FinishButton from "../components/FinishButton";
import Col from "react-bootstrap/Col";
import CheckboxInput from "../components/Input/CheckboxInput";
import {connect} from "react-redux";
import updateValue from "../reduxActions/updateValue";
import store from "../components/reduxStore/store";
import SiteQuestions from "../components/SiteQuestions";
import BackwardButton from "../components/BackwardsButton";
import ContinueButton from "../components/ContinueButton";


let numberOfDates = 0;

class FurtherQuestionsPage extends Component {

    async componentWillMount() {
        const reduxStore = store.getState();
        //check if the data is already loaded and user has an account -> redirect to landingpage
        if (!reduxStore.dataFetchedByAPI) {
            // consolelog("redirecting to landingpage for auth...")
            this.props.history.push("/s/-1");
        }
    };

    render() {
        return (
            <div className={"FurtherQuestionsPage"}>
                <HeaderBar/>
                <ProgressStepper activeState={3}/>
                <Col md={{span: 7, offset: 1}}>
                    <div style={(this.props.mark_incomplete_questions && !!this.props.interview_dates && this.props.interview_dates.size > 0)
                        ? {fontSize: '20px', marginBottom: '2%', fontWeight: 'bold', color: '#FF0000'}
                        : {fontSize: '20px', marginBottom: '2%'}}>
                        <b>* An welchen dieser Interviewtermine hast Du Zeit?</b>
                    </div>
                    {!!this.props.interview_dates ? this.props.interview_dates.map(date => (
                        <CheckboxInput description={"Ort: " + date.location} question={date.human_date}
                                       valueReduxKey={"interview_" + date.id} key={date.id}/>
                    )) : <div>Lade Interviewtermine...</div>}
                    <div className={"text-muted"}><small>Dein Interview findet innerhalb der ausgewählten Zeiträume statt und dauert ca. 1,5 Stunden</small></div>
                </Col>

                <SiteQuestions site_id={4}/>

                <ContinueButton linkTo={"/umfrage"}/>
                <BackwardButton linkTo={"/laufbahn"}/>
            </div>
        );
    }
}

const mapReduxStateToProps = (state) => {
    return {
        applicant_id: state.applicant_id,
        interview_dates: state.interview_dates,
        mark_incomplete_questions: state.mark_incomplete_questions
    };
};

const mapReduxDispatchToProps = (dispatch, ownProps) => {
    return {
        updateValue
    };
};

export default connect(mapReduxStateToProps, mapReduxDispatchToProps)(FurtherQuestionsPage);