import React, {Component} from "react";
import HeaderBar from "../components/HeaderBar";
import ProgressStepper from "../components/ProgressStepper";
import ContinueButton from "../components/ContinueButton";
import SmallTextInput from "../components/Input/SmallTextInput";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import WelcomeBackPopup from "../components/Snackbars/WelcomeBackPopup";
import SiteQuestions from "../components/SiteQuestions";
import DateInput from "../components/Input/DateInput";
import FileInput from "../components/Input/FileInput";
import {connect} from "react-redux";
import updateValue from "../reduxActions/updateValue";
import DropdownInput from "../components/Input/DropdownInput";
import PictureInput from "../components/Input/PictureInput";

class PersonalDataPage extends Component {

    componentWillMount() {
        //check if the data is already loaded and user has an account -> redirect to landingpage
        if(!this.props.dataFetchedByAPI) {
            // consolelog("redirecting to landingpage for auth...")
            this.props.history.push("/s/-1");
        }

    }

    render() {
        return (
            <div className={"PersonalDataPage"}>

                <HeaderBar/>
                <ProgressStepper activeState={0}/>
                {this.props.showUserAlreadyExistsInfo && <WelcomeBackPopup/>}
                <Row>
                    <Col md={{span: 5, offset: 1}}>
                        <SmallTextInput question={"Vorname"} placeholderValue={"Maxime"} key={"firstname"}
                                        valueReduxKey={"first_name"} required={true} question_marked={this.props.mark_incomplete_questions}/>
                        <SmallTextInput question={"Nachname"} placeholderValue={"Musternde"} key={"lastname"}
                                        valueReduxKey={"last_name"} required={true} question_marked={this.props.mark_incomplete_questions}/>
                        <DateInput question={"Geburtstag"} key={"geburtstag"} valueReduxKey={"birthday"}
                                   required={true} question_marked={this.props.mark_incomplete_questions}/>
                        <DropdownInput question={"Geschlecht"} options={["Weiblich", "Männlich", "Divers"]}
                                       valueReduxKey={"gender"} required={true} question_marked={this.props.mark_incomplete_questions}/>
                    </Col>
                    <Col md={{span: 5, offset: 0}}>
                        <PictureInput question={"Ein Bild von Dir"}
                                      description={"Akzeptierte Formate: .jpg und .png"}
                                      valueReduxKey={"picture"} required={true} question_marked={this.props.mark_incomplete_questions}/>
                        <SmallTextInput question={"E-Mail-Adresse"} placeholderValue={"name@example.de"} key={"email"}
                                        valueReduxKey={"email"} isEmailInput={true} required={true} question_marked={this.props.mark_incomplete_questions}/>
                        <SmallTextInput question={"Handynummer"} placeholderValue={"+49101133799"} key={"phone"}
                                        valueReduxKey={"phone"}
                                        description={"Wir verwenden dies ausschließlich, damit wir Dir Updates zur Bewerbung per SMS senden können."}
                                        required={true} question_marked={this.props.mark_incomplete_questions}/>
                        <FileInput question={"Lebenslauf"}
                                   required={true}
                                   key={"cv"}
                                   valueReduxKey={"cv"} question_marked={this.props.mark_incomplete_questions} description={"Akzeptierte Formate: .pdf"}/>
                    </Col>
                </Row>

                <SiteQuestions site_id={1}/>
                <ContinueButton linkTo={"/studydata"}/>
            </div>
        );
    }
}

const mapReduxStateToProps = (state) => {
    return {
        dataFetchedByAPI: state.dataFetchedByAPI,
        showUserAlreadyExistsInfo: state.showUserAlreadyExistsInfo,
        mark_incomplete_questions: state.mark_incomplete_questions
    };
};

const mapReduxDispatchToProps = (dispatch) => {
    return {
        updateValue: (key, value) => dispatch(updateValue(key, value))
    };
};


export default connect(mapReduxStateToProps, mapReduxDispatchToProps)(PersonalDataPage);