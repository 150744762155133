import React from "react";
import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import logo_weiß from "../assets/logo_weiß.png";
import background_pattern_header from "../assets/background-pattern-header.png";

const useStyles = makeStyles(theme => ({
    content: {
        padding: theme.spacing(2),
    },
    leftText: {
        fontSize: '20px',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '30px'
    },
    rightText: {
        fontSize: '20px',
        textAlign: 'right',
        paddingRight: '30px',
        paddingTop: '15px',
        paddingBottom: '15px'
    },
    middleText: {
        fontSize: '20px',
        textAlign: 'center',
        paddingTop: '15px',
        paddingBottom: '15px',
        fontWeight: 'bold'
    }
}));

const HeaderBar = (props) => {
    const classes = useStyles();
    return (
        <AppBar position={"sticky"} style={{height: "60px"}}>
            <Image src={background_pattern_header} style={{height: "60px", objectFit: "cover"}} resizemode="contain"/>
            <Row className='justify-content-between' style={{marginTop: "-60px"}}>
                <Col className={classes.leftText}>
                    <Image src={logo_weiß} style={{maxWidth: 800, maxHeight: 30, marginLeft: '10px'}}
                           resizemode="contain"/>
                </Col>
                {!!props.headline && <Col className={classes.middleText}>
                    {props.headline}
                </Col>}
                <Col className={classes.rightText}>
                    Bewerbung
                </Col>
            </Row>
        </AppBar>
    )
};

HeaderBar.propTypes = {
    headline: PropTypes.string
};

export default HeaderBar;