import React, {Component} from "react";
import LoadingWheel from "../components/LoadingWheel";
import HeaderBar from "../components/HeaderBar";
import updateValue from "../reduxActions/updateValue";
import {connect} from "react-redux";
import MissingInfoContentpane from "../components/MissingInfoContentpane";
import Container from "@material-ui/core/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class OverviewPage extends Component {

    componentDidMount() {
        //check if the data is already loaded and user has an account -> redirect to landingpage
        if(!this.props.dataFetchedByAPI) {
            this.props.updateValue("redirect_to_overview", true);
            // consolelog("redirecting to landingpage for auth...");
            this.props.history.push("/s/-1");
        }
    }

    render() {
        // consolelog("Overview-Page loading...")
        return (
            <div className={"OverviewPage"}>
                <HeaderBar headline={"Überblick über deine Bewerbung"}/>
                <Container>
                    <Row>
                        <Col md={{offset: 1, span: 10}}>
                            <div style={{marginTop: '20px'}}>
                                <MissingInfoContentpane/>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapReduxStateToProps = (state) => {
    return {
        dataFetchedByAPI: state.dataFetchedByAPI,
    };
};

const mapReduxDispatchToProps = (dispatch) => {
    return {
        updateValue: (key, value) => dispatch(updateValue(key, value))
    };
};


export default connect(mapReduxStateToProps, mapReduxDispatchToProps)(OverviewPage);