import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CircularProgress from '@material-ui/core/CircularProgress';
import DefaultACTheme from "../styles/DefaultACTheme";

const LoadingWheel = () => {
    return(
        <Row>
            <Col md={{span: 1}}>
                <CircularProgress color={"primary"} disableShrink/>
            </Col>
            <Col md={{span: 4, offset: 0}} style={{
                color: DefaultACTheme.palette.primary.main,
                fontSize: '20px'
            }}>
                Lade Daten...
            </Col>
        </Row>
    );
}

export default LoadingWheel;