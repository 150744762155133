import React, {Component} from "react";
import HeaderBar from "../components/HeaderBar";
import PropTypes from "prop-types";
import DefaultACTheme from "../styles/DefaultACTheme";
import Row from "react-bootstrap/Row";
import Paper from "@material-ui/core/Paper";
import AC_Banner from "../assets/logo_graublau.png";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import PhaseInformation from "../components/PhaseInformation";
import API from "../utils/API";
import {connect, useDispatch} from "react-redux";
import updateValue from "../reduxActions/updateValue";
import SomethingWentWrongPopup from "../components/Snackbars/SomethingWentWrongPopup";
import LoadingSiteInfo from "../components/LoadingSiteInfo";
import store from "../components/reduxStore/store";
import CheckboxInput from "../components/Input/CheckboxInput";
import Checkbox from "@material-ui/core/Checkbox";

class WelcomePage extends Component {
    state = {
        headline: "Startseite",
        subHeadline: "Herzlich willkommen bei AcademyConsult! Schön, dass du hergefunden hast!",
        picture: this.props.picture,
        message: "Schön, dass du zu unserer Bewerberseite gefunden hast! Du möchtest auch " +
            "kennenlernen, wie wir arbeiten und stetig mit uns wachsen? Du möchtest auch ein tolles Vereinsleben " +
            "und engen Kontakt zu Unternehmen? Dann bewirb dich jetzt bei uns und lerne uns kennen!",
        buttonText: "Let's go!",
        messageHeadline: "Hey du!",
        isPhaseOpen: true,
        isLoading: true,
        isError: false,
        isCreatingApplicant: false,
        accepted_terms: false
    };

    handleButtonClick = async e => {
        this.setState({isCreatingApplicant: true});
        localStorage.removeItem("applicant_key");
        localStorage.removeItem("applicant_id");
        try {
            await this.startApplication();
            this.props.history.push("/personaldata")
        } catch (e) {
            this.setState({isError: true});
        }
    };

    async startApplication() {
        // consolelog("starting Application...")
        let landingpageid = this.props.location.pathname.substring(3);
        if (!landingpageid.match("[0-9]")) {
            landingpageid = -1;
        }
        // consolelog("creating new applicant...")
        let newApplicant;
        if (landingpageid != -1) {
            newApplicant = await API.post("applicant", {landing_page_id: landingpageid});//POST-create-applicant
        } else {
            newApplicant = await API.post("applicant");//POST-create-applicant
        }

        // consolelog("created applicant!");

        //and feed into localstorage
        localStorage.setItem("applicant_key", newApplicant.data.key);
        localStorage.setItem("applicant_id", newApplicant.data.id);
        localStorage.setItem("just_created", true);
        this.props.updateValue("applicant_key", localStorage.getItem("applicant_key"));
        this.props.updateValue("applicant_id", localStorage.getItem("applicant_id"));

        this.props.updateValue("dataFetchedByAPI", true);
        // consolelog("updated all values!");

        window.location.reload();
    }

    async componentDidMount() {
        /*used to verify if user already has an account
            yes: load data from API if not already done
            no: create account and DONT load first_name etc from API
         */
        const reduxState = store.getState();

        //check if the user already has an application
        if (this.props.applicant_id !== -1) {
            //check if there is an active phase right now (if call fails -> no)
            try {
                await API.get("phase/current");
            } catch (e) {
                // consolelog("No open application-phase: deleting local-data");
                localStorage.removeItem("applicant_key");
                localStorage.removeItem("applicant_id");
                this.props.updateValue("applicant_key", '');
                this.props.updateValue("applicant_id", -1);
            }

            //check if data from API is already fetched
            if (this.props.dataFetchedByAPI) {
                //data is fetched -> just redirect
                this.props.updateValue("showUserAlreadyExistsInfo", true);
                if (reduxState.redirect_to_overview) {
                    this.props.history.push("/overview");
                } else {
                    this.props.history.push("/personaldata");
                }

            } else {
                // consolelog("fetching data...");
                //fetch all data and set flags
                //just created -> dont load just set flags
                if (localStorage.getItem("just_created")) {
                    localStorage.removeItem("just_created");

                    //update laufbahn
                    //this.props.updateValue("motivation", applicantData.motivation); not used anymore
                    let motivation_questions = await API.get("screening_questions");
                    motivation_questions = motivation_questions.data;
                    this.props.updateValue("motivation_questions", motivation_questions);

                    //updating site-questions
                    let sitequestionsData = await API.get("/questions");
                    sitequestionsData = sitequestionsData.data;

                    let questions1 = [];
                    let questions2 = [];
                    let questions3 = [];
                    let questions4 = [];
                    let questions5 = [];

                    sitequestionsData.map(entry => {
                        switch (entry.step) {
                            case(1):
                                questions1.push(entry);
                                break;
                            case(2):
                                questions2.push(entry);
                                break;
                            case(3):
                                questions3.push(entry);
                                break;
                            case(4):
                                questions4.push(entry);
                                break;
                            case(5):
                                questions5.push(entry);
                                break;
                            default:
                                throw new Error("Unkown page to save side questions")
                        }
                    });

                    let questions = {
                        1: questions1,
                        2: questions2,
                        3: questions3,
                        4: questions4,
                        5: questions5
                    };

                    this.props.updateValue("questions", questions);

                    //update interview dates
                    let interviewDates = await API.get('/phase/current');
                    interviewDates = interviewDates.data.interview_dates;
                    this.props.updateValue("interview_dates", interviewDates);

                    this.props.updateValue("dataFetchedByAPI", true);

                    this.props.history.push("/personaldata");
                } else {


                    try {
                        //update applicantData
                        let applicantData = await API.get("applicant/" + this.props.applicant_id);
                        applicantData = applicantData.data;

                        this.props.updateValue("first_name", applicantData.first_name);
                        this.props.updateValue("last_name", applicantData.last_name);
                        this.props.updateValue("birthday", applicantData.birthday);
                        //converting letter of gender to index of gender
                        let gender_letter = applicantData.gender;
                        let gender_id;
                        switch (gender_letter) {
                            case("w"):
                                gender_id = 0;
                                break;
                            case("m"):
                                gender_id = 1;
                                break;
                            case("d"):
                                gender_id = 2;
                                break;
                            default:
                                gender_id = null;
                                break;
                        }
                        this.props.updateValue("gender", gender_id);
                        this.props.updateValue("phone", applicantData.phone);
                        this.props.updateValue("email", applicantData.email);
                        this.props.updateValue("cv", applicantData.cv);

                        //update photo
                        try {
                            let photo = await API.get("applicant/" + this.props.applicant_id + "/photo",
                                {responseType: "blob"});
                            this.props.updateValue("picture", photo.data);
                        } catch (e) {
                            //just not yet existent
                        }

                        //update certificateData
                        let certificateData = await API.get("applicant/" + this.props.applicant_id + "/certificate");
                        certificateData = certificateData.data;
                        this.props.updateValue("university_id", certificateData.university_id);
                        this.props.updateValue("semester", certificateData.semester);
                        this.props.updateValue("remaining_semester", certificateData.remaining_semester);
                        this.props.updateValue("branch_of_study_id", certificateData.branch_of_study_id);
                        this.props.updateValue("course_of_studies", certificateData.course_of_studies);
                        this.props.updateValue("degree_id", certificateData.degree_id);
                        this.props.updateValue("number", certificateData.number);
                        this.props.updateValue("letter_of_immatriculation", certificateData.file);


                        //update laufbahn
                        //this.props.updateValue("motivation", applicantData.motivation); not used anymore
                        let motivation_questions = await API.get("screening_questions");
                        motivation_questions = motivation_questions.data;
                        this.props.updateValue("motivation_questions", motivation_questions);

                        //update interview dates
                        let interviewDates = await API.get('/phase/current');
                        interviewDates = interviewDates.data.interview_dates;
                        this.props.updateValue("interview_dates", interviewDates);

                        let interviewData = await API.get('applicant/' + this.props.applicant_id + '/interview_dates');
                        if (!!interviewData.data) {
                            interviewData.data.map(date => {
                                this.props.updateValue("interview_" + date.id, true);
                            })
                        }

                        //updating site-questions
                        let sitequestionsData = await API.get("/questions");
                        sitequestionsData = sitequestionsData.data;

                        let questions1 = [];
                        let questions2 = [];
                        let questions3 = [];
                        let questions4 = [];
                        let questions5 = [];

                        sitequestionsData.map(entry => {
                            switch (entry.step) {
                                case(1):
                                    questions1.push(entry);
                                    break;
                                case(2):
                                    questions2.push(entry);
                                    break;
                                case(3):
                                    questions3.push(entry);
                                    break;
                                case(4):
                                    questions4.push(entry);
                                    break;
                                case(5):
                                    questions5.push(entry);
                                    break;
                                default:
                                    throw new Error("Unkown page to save side questions")
                            }
                        });

                        let questions = {
                            1: questions1,
                            2: questions2,
                            3: questions3,
                            4: questions4,
                            5: questions5,
                        };

                        // consolelog(questions);

                        this.props.updateValue("questions", questions);

                        API.get(`/applicant/${reduxState.applicant_id}/screening_questions/answers`).then((result) => {
                            result.data.map((data) => {
                                this.props.updateValue('motivation_answer_'+data.screening_question_id, data.answer);
                            });
                        });

                        let sitequestionsAnswersData = await API.get("/applicant/" + reduxState.applicant_id + "/questions/responses");
                        sitequestionsAnswersData = sitequestionsAnswersData.data;

                        //Warning: In questionsAnswers the ID of the question is the identifier (.ID) under which the answer is
                        //In questions the identifier is the the SITE-ID and then there is a array of question-objects

                        let questionsAnswers = null;
                        if (!!sitequestionsAnswersData) {
                            questionsAnswers = sitequestionsAnswersData;
                            //iterating over given questions in order to find answers
                            sitequestionsData.map(quest => {
                                if (!!questionsAnswers[quest.id]) {
                                    this.props.updateValue("questionsAnswers." + quest.id, questionsAnswers[quest.id]);
                                }
                            })
                        }

                        //setting completed-state
                        //personal
                        if (!!applicantData.gender && !!applicantData.first_name && !!applicantData.last_name &&
                            !!applicantData.birthday && !!applicantData.email && !!applicantData.phone &&
                            !!applicantData.photo && !!applicantData.cv) {
                            this.props.updateValue("personal_filled", true);
                        }

                        //study
                        if (!!certificateData.university_id && !!certificateData.semester &&
                            !!certificateData.remaining_semester && !!certificateData.branch_of_study_id &&
                            !!certificateData.course_of_studies && !!certificateData.degree_id &&
                            !!certificateData.number && !!certificateData.file) {
                            this.props.updateValue("study_filled", true);
                        }

                        //laufbahn
                        if (!!applicantData.motivation) {
                            this.props.updateValue("laufbahn_filled", true);
                        }

                        //furtherquestions
                        if (!!interviewData.data && interviewData.data.size > 0) {
                            this.props.updateValue("furtherquestions_filled", true);
                        }

                        //application-status
                        this.props.updateValue("application_complete", applicantData.applicant_completed_at);

                        //site-questions
                        for (let i = 1; i <= 5; i++) {
                            let questions_of_step = questions[i];

                            let questions_complete = true;
                            if (!!questions_of_step) {
                                questions_of_step.map(quest => {
                                    let answer = questionsAnswers[quest.id];
                                    if (!!!answer && quest.is_required) {
                                        questions_complete = false;
                                    }
                                })
                            }
                            // consolelog("Questions_filled for step " + i + ": " + questions_complete);
                            if (questions_complete) {
                                this.props.updateValue("questions" + i + "_filled", true);
                            }
                        }


                        //end sequence
                        this.props.updateValue("dataFetchedByAPI", true);
                        this.props.updateValue("showUserAlreadyExistsInfo", true);

                        if (reduxState.redirect_to_overview) {
                            this.props.history.push("/overview");
                        } else {
                            this.props.history.push("/personaldata");
                        }

                    } catch (e) {
                        // consolelog("Error fetching data from API!")
                        // consolelog(e);
                    }
                }
            }
        }

        //loading landingpage-info
        var landingpageid = this.props.location.pathname.substring(3);
        if (!landingpageid.match("[0-9]")) {
            landingpageid = -1;
        }

        //getting landing-page-info from API
        if (landingpageid != -1) {
            try {
                let landingdata = await API.get('landing_pages/' + landingpageid);
                let picturedata = await API.get('landing_pages/' + landingpageid + '/picture');

                landingdata = landingdata.data;

                this.setState({
                    headline: landingdata.headline,
                    subHeadline: landingdata.sub_headline,
                    message: landingdata.message,
                    messageHeadline: landingdata.message_headline,
                    buttonText: landingdata.button_text,
                    picture: (picturedata.config.baseURL + '/' + picturedata.config.url)
                })

                API.post('landing_pages/'+landingpageid+'/openings');
            } catch (e) {
            }
        }

        //Phase-Request (used to work with button)
        try {
            let phaseData = await API.get('/phase/current');
        } catch (err) {
            // consolelog(err);
            this.setState({isPhaseOpen: false});
        }

        this.setState({isLoading: false});
    }

    render() {
        // consolelog('render landning page component');


        return (
            <div className={"LandingPage"}>
                {(this.state.isLoading || this.props.applicant_id !== -1) &&
                <div style={{position: 'fixed', top: '14rem', left: '33%'}}>
                    <LoadingSiteInfo/>
                </div>}
                {!this.state.isLoading && this.props.applicant_id === -1 && <div>
                    <HeaderBar headline={this.state.headline}/>
                    {this.state.isError && <SomethingWentWrongPopup/>}
                    <Row className={"justify-content-center"}>
                        <div style={{
                            marginTop: '10px',
                            marginBottom: '10px',
                            color: DefaultACTheme.palette.primary.main,
                            fontSize: '20px',
                            fontWeight: 'bold'
                        }}>
                            {this.state.subHeadline}
                        </div>
                    </Row>
                    <Row className={"justify-content-center"} style={{marginBottom: '3%'}}>
                        <Paper variant={"outlined"}>
                            <Image src={this.state.picture?this.state.picture:AC_Banner}
                                   style={{maxWidth: '800px', width: '100%', height: 'auto', margin: '10px'}}
                                   resizemode="contain"
                            />
                        </Paper>
                    </Row>
                    <div className={"container"}>
                        <Row className={"justify-content-around"}>
                            <Col md={{span: 6}}>
                                <h1 style={{
                                    fontSize: '25px',
                                    color: DefaultACTheme.palette.primary.main,
                                    fontWeight: 'bold',
                                    paddingBottom: '3%'
                                }}>
                                    {this.state.messageHeadline}
                                </h1>
                                <div style={{
                                    whiteSpace: 'pre-line',
                                    display: 'block',
                                }}>
                                    {this.state.message}
                                </div>

                            </Col>
                            <Col md={{span: 4, offset: 0}}>
                                <PhaseInformation/>
                            </Col>
                        </Row>
                    </div>

                    <div className={"container text-center mb-5"}>
                        <label style={{color: DefaultACTheme.palette.secondary.main}}>

                            <Checkbox color={"primary"} onChange={(event) => {this.setState({accepted_terms: event.target.checked})}} defaultChecked={false} size={"small"}/>
                            Ich stimme der Verarbeitung meiner Daten nach den&nbsp;
                            <a href={process.env.REACT_APP_TERMS_URL} target={"_blank"} style={{color: DefaultACTheme.palette.primary.main}}>Datenschutzbestimmungen</a>
                            &nbsp;zu.

                        </label>
                    </div>

                    {!this.state.isLoading && this.state.accepted_terms && <Button variant={"contained"} key={"go"} size={"large"} fullWidth
                                                      onClick={this.state.isPhaseOpen ? this.handleButtonClick : undefined}
                                                      color={"primary"} style={{
                        position: 'fixed',
                        bottom: '3%',
                        left: '25%',
                        width: '50%',
                        backgroundColor: DefaultACTheme.palette.primary.main,
                        color: '#FFFFFF'
                    }}>
                        <div style={{fontWeight: 'bold'}}>
                            {this.state.isPhaseOpen && (this.state.isCreatingApplicant ? "Bereite Bewerbung vor..." : this.state.buttonText)}
                            {!this.state.isPhaseOpen && "Keine Bewerbungsphase geöffnet!"}
                        </div>

                    </Button>
                    }
                </div>}
            </div>
        );
    }
}

const mapReduxStateToProps = (state) => {
    return {
        photo: state.picture,
        first_name: state.first_name,
        applicant_id: state.applicant_id,
        showUserAlreadyExistsInfo: state.showUserAlreadyExistsInfo,
        dataFetchedByAPI: state.dataFetchedByAPI
    };
};

const mapReduxDispatchToProps = (dispatch, ownProps) => {
    return {
        updateValue: (key, value) => dispatch(updateValue(key, value))
    };
};

WelcomePage.propTypes = {
    headline: PropTypes.string,
    subHeadline: PropTypes.string,
    picture: PropTypes.string,
    messageHeadline: PropTypes.string,
    message: PropTypes.string,
    buttonText: PropTypes.string
};

export default connect(mapReduxStateToProps, mapReduxDispatchToProps)(WelcomePage);