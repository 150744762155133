import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import DefaultACTheme from "../../styles/DefaultACTheme";
import {useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px',
    },
    title: {
        fontSize: '25px',
        marginBottom: '10px',
    },
    question: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main
    }
}))

const DropdownInput = (props) => {
    const classes = useStyles();

    const value = useSelector(state => state[props.valueReduxKey]);
    const dispatch = useDispatch();

    const handleChange = (event) => {
        dispatch(updateValue(props.valueReduxKey, event.target.value));
    };

    let count = -1;
    return (
        <Container>
            <div className={classes.card}>
                <Row>
                    <Col>
                        <div className={(props.question_marked && !!!value) ? classes.question_marked : classes.question}>
                            {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                        </div>
                        {!!props.description && <div className={classes.description}>
                            {props.description}
                        </div>}
                    </Col>
                    <Col>
                        <TextField fullWidth id={props.key} label="Deine Antwort" select variant={"outlined"} onChange={handleChange} defaultValue={value!=null ? value : null}>
                            {!!props.options && props.options.map(item => {
                                count++;
                                let val = count;
                                if(!!props.optionsMapping) {
                                    val = props.optionsMapping[count];
                                }
                                return <MenuItem value={val}>{item}</MenuItem>;
                            })}
                            {!!!props.options && <MenuItem value={0} disabled={true}>ERROR - please pass options!</MenuItem>}
                        </TextField>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};

DropdownInput.propTypes = {
    question: PropTypes.string,
    key: PropTypes.string,
    options: PropTypes.array,
    optionsMapping: PropTypes.array,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    question_marked: PropTypes.bool
};

export default DropdownInput;