import React, {useState} from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import palette from "../styles/DefaultACTheme";
import Row from "react-bootstrap/Row";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ReceiptIcon from '@material-ui/icons/Receipt';
import Col from "react-bootstrap/Col";
import TimelapseIcon from '@material-ui/icons/Timelapse';
import API from "../utils/API";
import LoadingWheel from "./LoadingWheel";
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
    title: {
        fontSize: '25px',
        color: palette.palette.primary.main,
        fontWeight: 'bold',
        paddingBottom: '3%'
    },
    info: {
        fontSize: '20px',
        color: palette.palette.secondary.dark,
        paddingBottom: '3%'
    }
}));

const PhaseInformation = (props) => {
    const classes = useStyles();

    //Creating state
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(true);
    const [isError, setIsError] = useState(false);
    const [phaseName, setPhaseName] = useState("PhaseName Default Value");
    const [phaseStart, setPhaseStart] = useState("PhaseStart Default Value");
    const [phaseEnd, setPhaseEnd] = useState("PhaseEnd Default Value");
    const [feedbackUntil, setFeedbackUntil] = useState("FeedbackUntil Default Value");
    const [current, setCurrent] = useState(true);
    const [upcoming, setUpcoming] = useState(true);

    if(isLoading && current) {
        fetchPhase().then(r => r ? setupData(r) : setupError());
    }

    function setupData(data) {
        let phaseData = data;
        phaseData = phaseData.data;

        //loading values
        setPhaseName(phaseData.name);
        setPhaseStart(phaseData.start_at_human);
        setPhaseEnd(phaseData.finish_at_human);
        setFeedbackUntil(phaseData.feedback_until_human);

        setIsOpen(phaseData.is_open);

        //release waiting state
        setIsLoading(false);
    }

    function setupError() {
        setIsError(true);
        setIsLoading(false);
    }

    async function fetchPhase() {
        let phaseData = null;
        try {
            phaseData = await API.get('/phase/current');
            return phaseData;
        } catch (err) {
            try {
                phaseData = await API.get('/phase/upcoming');
                return phaseData;
            } catch (err) {
                return null;
            }
        }
    }


    return (
        <div>
            {isLoading && <LoadingWheel/>}
            {!isLoading && <div>
                <Row className={classes.title}>
                    <Col>
                        {isOpen ? "Aktuelle " : "Nächste "} Bewerbungsphase:
                    </Col>

                </Row>
                {isError && <div>
                    <Row>
                        <Col md={{span: 1}}>
                            <ClearIcon color={"primary"} fontSize={"large"}/>
                        </Col>
                        <Col className={classes.info} md={{offset: 1}}>
                            Noch keine Bewerbungsphase bekannt - besuche uns bitte zu einem späteren Zeitpunkt!
                        </Col>
                    </Row>
                </div>}
                {!isError && <div>
                    <Row>
                        <Col md={{span: 1}}>
                            <ReceiptIcon color={"primary"} fontSize={"large"}/>
                        </Col>
                        <Col className={classes.info} md={{offset: 1}}>
                            {phaseName}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{span: 1}}>
                            <TimelapseIcon color={"primary"} fontSize={"large"}/>
                        </Col>
                        <Col className={classes.info} md={{offset: 1}}>
                            {phaseStart} bis {phaseEnd}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{span: 1}}>
                            <PlayCircleOutlineIcon color={"primary"} fontSize={"large"}/>
                        </Col>
                        <Col className={classes.info} md={{offset: 1}}>
                            Rückmeldung bis {feedbackUntil}
                        </Col>
                    </Row>
                </div>
                }
            </div>}
        </div>
    );
};

export default PhaseInformation;