import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import Fab from "@material-ui/core/Fab";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import * as PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    card: {
        position: "fixed",
        bottom: "20px",
        left: "20px"
    }
}));

const BackwardButton = (props) => {
    const classes = useStyles();
    let history = useHistory();

    const handleClick = () => {
        history.push(props.linkTo);
    };

    return (
        <div>
            <Fab className={classes.card} color={"primary"} variant={"extended"} onClick={handleClick}>
                    <div>
                        <ArrowBackIosIcon/>
                        Zurück
                    </div>
            </Fab>
        </div>

    )
};

BackwardButton.propTypes = {
    linkTo: PropTypes.string
};

export default BackwardButton;