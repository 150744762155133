import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import DefaultACTheme from "../../styles/DefaultACTheme";
import {useDispatch, useSelector} from "react-redux";
import updateValue from "../../reduxActions/updateValue";

const useStyles = makeStyles(theme => ({
    card: {
        marginBottom: '10px',
    },
    question: {
        fontSize: '20px',
        fontWeight: 'bold'
    },
    question_marked: {
        fontSize: '20px',
        fontWeight: 'bold',
        color: '#FF0000'
    },
    description: {
        fontSize: '12px',
        color: DefaultACTheme.palette.secondary.main
    }
}));

const SmallTextInput = (props) => {
    const classes = useStyles();

    const [error, setError] = useState(false);

    const value = useSelector(state => state[props.valueReduxKey]);
    const dispatch = useDispatch();

    function handleValueChange(value) {
        if(props.isEmailInput) {
            if(value.match(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/)) {
                setError(false);
                dispatch(updateValue(props.valueReduxKey, value));
            } else {
                setError(true);
            }
        } else {
            dispatch(updateValue(props.valueReduxKey, value));
        }
    }

    return (
        <Container>
            <div className={classes.card}>
                <Row>
                    <Col>
                        <div className={(props.question_marked && !!!value) ? classes.question_marked : classes.question}>
                            {props.question} {props.required && <span style={{color: DefaultACTheme.palette.primary.main}}> *</span>}
                        </div>
                        {!!props.description && <div className={classes.description}>
                            {props.description}
                        </div>}
                    </Col>
                    <Col>
                        <TextField fullWidth label={error ? "Die Eingabe passt nicht zum Format" : "Deine Antwort"} variant={"outlined"} error={error}
                                   placeholder={props.placeholderValue} id={props.key} type={props.isNumberInput ? "number" : (props.isEmailInput ? "email" : null)} defaultValue={!!value ? value : null} onChange={(event) => handleValueChange(event.target.value)}/>
                    </Col>
                </Row>
            </div>
        </Container>
    )
};

SmallTextInput.propTypes = {
    question: PropTypes.string,
    placeholderValue: PropTypes.string,
    key: PropTypes.string,
    required: PropTypes.bool,
    description: PropTypes.string,
    valueReduxKey: PropTypes.string,
    isNumberInput: PropTypes.bool,
    isEmailInput: PropTypes.bool,
    question_marked: PropTypes.bool
};

export default SmallTextInput;