import React, {Component} from "react";
import HeaderBar from "../components/HeaderBar";
import ProgressStepper from "../components/ProgressStepper";
import ContinueButton from "../components/ContinueButton";
import Col from "react-bootstrap/Col";
import SmallTextInput from "../components/Input/SmallTextInput";
import Row from "react-bootstrap/Row";
import DropdownInput from "../components/Input/DropdownInput";
import FileInput from "../components/Input/FileInput";
import API from "../utils/API";
import store from "../components/reduxStore/store";
import SiteQuestions from "../components/SiteQuestions";
import BackwardButton from "../components/BackwardsButton";

class StudyDataPage extends Component {
    state = {
        universities: ["Auswahl lädt..."],
        universities_mapping: [0],
        degrees: ["Auswahl lädt..."],
        degrees_mapping: [0],
        branch_of_studies: ["Auswahl lädt..."],
        branch_of_studies_mapping: [0],
        mark_incomplete_questions: false
    };

    async componentDidMount() {
        const reduxStore = store.getState();
        //check if the data is already loaded and user has an account -> redirect to landingpage
        if (!reduxStore.dataFetchedByAPI) {
            // consolelog("redirecting to landingpage for auth...")
            this.props.history.push("/s/-1");
        }
        try {
            const mark_status = reduxStore["mark_incomplete_questions"];
            this.setState({mark_incomplete_questions: mark_status});

            var universityRequest = await API.get("universities");
            var universitiesArr = this.state.universities;
            var universitiesMappingArr = this.state.universities_mapping;
            universitiesArr.pop();
            universitiesMappingArr.pop();
            universityRequest.data.map(uni => {
                universitiesArr.push(uni.name);
                universitiesMappingArr.push(uni.id);
            });
            this.setState({universities: universitiesArr, universities_mapping: universitiesMappingArr});
        } catch (e) {
            this.setState({universities: ["Fehler beim Laden"], universities_mapping: [0]});
        }

        try {
            var degreeRequest = await API.get("degrees");
            var degreesArr = this.state.degrees;
            var degreesMappingArr = this.state.degrees_mapping;
            degreesArr.pop();
            degreesMappingArr.pop();
            degreeRequest.data.map(degree => {
                degreesArr.push(degree.name);
                degreesMappingArr.push(degree.id);
            });
            this.setState({degrees: degreesArr, degrees_mapping: degreesMappingArr});
        } catch (e) {
            this.setState({degrees: ["Fehler beim Laden"], universities_mapping: [0]});
        }

        try {
            var branchRequest = await API.get("branch_of_studies");
            var branchesArr = this.state.branch_of_studies;
            var branchesMappingArr = this.state.branch_of_studies_mapping;
            branchesArr.pop();
            branchesMappingArr.pop();
            branchRequest.data.map(branch => {
                branchesArr.push(branch.name);
                branchesMappingArr.push(branch.id);
            });
            this.setState({branch_of_studies: branchesArr, branch_of_studies_mapping: branchesMappingArr});
        } catch (e) {
            this.setState({branch_of_studies: ["Fehler beim Laden"], branch_of_studies_mapping: [0]});
        }
    }

    render() {
        return (
            <div className={"StudyDataPage"}>
                <HeaderBar/>
                <ProgressStepper activeState={1}/>
                <Row>
                    <Col sm={{span: 10, offset: 1}} md={{span: 5, offset: 1}}>
                        <DropdownInput question={"Hochschule"} options={this.state.universities} optionsMapping={this.state.universities_mapping}
                                       required={true} valueReduxKey={"university_id"} question_marked={this.state.mark_incomplete_questions}/>
                        <SmallTextInput question={"Fachsemester"} placeholderValue={"3"} valueReduxKey={"semester"} required={true}
                                        isNumberInput={true} description={"Anzahl Deiner aktiven Semester im aktuellen Studienabschnitt (BA, MA,..) inkl. diesem"}
                                        question_marked={this.state.mark_incomplete_questions}/>
                        <SmallTextInput question={"Verbleibende Semester"} placeholderValue={"3"} required={true}
                                        valueReduxKey={"remaining_semester"} isNumberInput={true}
                                        description={"Anzahl verbleibender Semester im aktuellen Studienabschnitt (BA, MA,..) exkl. diesem"}
                                        question_marked={this.state.mark_incomplete_questions}/>
                    </Col>
                    <Col sm={{span: 10, offset: 1}} md={{span: 5, offset: 0}}>
                        <DropdownInput question={"Studienrichtung"} options={this.state.branch_of_studies}
                                       optionsMapping={this.state.branch_of_studies_mapping}
                                       valueReduxKey={"branch_of_study_id"} required={true}
                                       question_marked={this.state.mark_incomplete_questions}/>
                        <SmallTextInput question={"Studiengang"} valueReduxKey={"course_of_studies"}
                                        required={true} question_marked={this.state.mark_incomplete_questions}/>
                        <DropdownInput question={"Angestrebter Abschluss"} options={this.state.degrees}
                                       optionsMapping={this.state.degrees_mapping}
                                       valueReduxKey={"degree_id"} required={true}
                                       question_marked={this.state.mark_incomplete_questions}/>
                        <FileInput
                            question={"Immatrikulationsbescheinigung"} key={"immatrikulation"} required={true}
                            description={"Die Immatrikulationsbescheinigung findest Du auf der Website Deiner Hochschule"}
                            valueReduxKey={"letter_of_immatriculation"} question_marked={this.state.mark_incomplete_questions}/>
                    </Col>
                </Row>

                <SiteQuestions site_id={2}/>

                <ContinueButton linkTo={"/laufbahn"}/>
                <BackwardButton linkTo={"/personaldata"}/>
            </div>
        );
    }
}

export default StudyDataPage;