import React, {useState} from "react";
import Fab from "@material-ui/core/Fab";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import API from "../utils/API";
import store from "../components/reduxStore/store";
import SomethingWentWrongPopup from "./Snackbars/SomethingWentWrongPopup";
import {useDispatch} from "react-redux";
import updateValue from "../reduxActions/updateValue";
import CircularProgress from "@material-ui/core/CircularProgress";
import {array} from "prop-types";

const useStyles = makeStyles(theme => ({
    card: {
        position: "fixed",
        bottom: "20px",
        right: "20px"
    }
}));


const ContinueButton = (props) => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    let history = useHistory();

    const dispatch = useDispatch();

    async function handleClick() {
        setLoading(true);
        const reduxState = store.getState();
        try { //big try-catch in order to display somethingwentwrong
            switch (props.linkTo) {
                case ("/studydata"): { //we are coming from personaldata
                    //disable welcome-back-popup if available
                    dispatch(updateValue("showUserAlreadyExistsInfo", false));

                    let gender; //needs conversion from index to m/w/d
                    switch (reduxState.gender) {
                        case (0): gender = "w"; break;
                        case(1): gender = "m"; break;
                        case(2): gender = "d"; break;
                        default: gender = null; break;
                    }
                    let paramsPersonalData = {
                        first_name: reduxState.first_name,
                        last_name: reduxState.last_name,
                        birthday: reduxState.birthday,
                        gender: gender,
                        email: reduxState.email,
                        phone: reduxState.phone
                    };

                    if(!!reduxState.picture && reduxState.picture_changed) {
                        let photoFormData = new FormData();
                        photoFormData.append("photo", reduxState.picture);
                        await API.post("applicant/" + reduxState.applicant_id + "/photo", photoFormData);
                    }

                    if(!!reduxState.cv && reduxState.cv_changed) {
                        let cvFormData = new FormData();
                        cvFormData.append("cv", reduxState.cv);
                        await API.post("applicant/" + reduxState.applicant_id + "/cv", cvFormData);
                    }

                    //saving sitequestions
                    let questions = reduxState.questions[1];
                    let questions_complete = true;
                    if(!!questions && questions.length > 0) {
                        questions.map(quest => {
                            let answer = reduxState["questionsAnswers." + quest.id];
                            if(!!answer) {
                                //checking if answer is already in an array
                                try {
                                    if(answer instanceof Array) {
                                        let addition = {values: answer}
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    } else {
                                        let answerPort = [];
                                        answerPort.push(answer);
                                        let addition = {
                                            values: answerPort
                                        };
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    }
                                } catch (e) {
                                    let answerPort = [];
                                    answerPort.push(answer);
                                    let addition = {
                                        values: answerPort
                                    };
                                    API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                }
                            } else {
                                questions_complete = false;
                            }
                        })
                    }

                    if(questions_complete) {
                        dispatch(updateValue("questions1_filled", true));
                    } else {
                        dispatch(updateValue("questions1_filled", false));
                    }

                    //setting completed-state
                    if(!!reduxState.first_name && !!reduxState.last_name && !!reduxState.birthday
                         && !!reduxState.email && !!reduxState.phone && !!reduxState.picture && !!reduxState.cv) {
                        dispatch(updateValue("personal_filled", true));
                    } else {
                        dispatch(updateValue("personal_filled", false));
                    }

                    await API.post("applicant/" + reduxState.applicant_id, paramsPersonalData).then(history.push(props.linkTo));
                    break;
                }
                case("/laufbahn"): { //we are coming from studydata
                    let paramsStudyData = {
                        university_id: reduxState.university_id,
                        semester: reduxState.semester,
                        remaining_semester: reduxState.remaining_semester,
                        branch_of_study_id: reduxState.branch_of_study_id,
                        course_of_studies: reduxState.course_of_studies,
                        degree_id: reduxState.degree_id,
                        number: reduxState.number
                    };

                    if(!!reduxState.letter_of_immatriculation && reduxState.letter_of_immatriculation_changed) {
                        let immatFormData = new FormData();
                        immatFormData.append("file", reduxState.letter_of_immatriculation);
                        await API.post("applicant/" + reduxState.applicant_id + "/certificate/file", immatFormData);
                    }

                    //setting complete-state
                    if(!!reduxState.university_id && !!reduxState.semester && !!reduxState.remaining_semester &&
                        !!reduxState.branch_of_study_id && !!reduxState.course_of_studies && !!reduxState.degree_id &&
                        !!reduxState.letter_of_immatriculation) {
                        dispatch(updateValue("study_filled", true));
                    } else {
                        dispatch(updateValue("study_filled", false));
                    }

                    //saving sitequestions
                    let questions = reduxState.questions[2];
                    let questions_complete = true;
                    if(!!questions && questions.length > 0) {
                        questions.map(quest => {
                            let answer = reduxState["questionsAnswers." + quest.id];
                            if(!!answer) {
                                //checking if answer is already in an array
                                try {
                                    if(answer instanceof Array) {
                                        let addition = {values: answer}
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    } else {
                                        let answerPort = [];
                                        answerPort.push(answer);
                                        let addition = {
                                            values: answerPort
                                        };
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    }
                                } catch (e) {
                                    let answerPort = [];
                                    answerPort.push(answer);
                                    let addition = {
                                        values: answerPort
                                    };
                                    API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                }
                            } else {
                                questions_complete = false;
                            }
                        })
                    }

                    if(questions_complete) {
                        dispatch(updateValue("questions2_filled", true));
                    } else {
                        dispatch(updateValue("questions2_filled", false));
                    }

                    await API.post("applicant/" + reduxState.applicant_id + "/certificate", paramsStudyData).then(history.push(props.linkTo));
                    break;
                }
                case("/furtherquestions"): {
                    let mot_questions = reduxState["motivation_questions"];
                    let mot_questions_filled = true;
                    mot_questions.map(async quest => {
                       if(!!reduxState["motivation_answer_" + quest.id]) {
                           // consolelog("pushing question " + quest.id);
                           let answerJSON = {
                               answer: reduxState["motivation_answer_" + quest.id]
                           };
                           // consolelog(answerJSON);
                           await API.post("applicant/" + reduxState.applicant_id + "/screening_questions/" + quest.id + "/answer", answerJSON);

                       } else {
                           mot_questions_filled = false;
                       }
                    });

                    //setting complete-state
                    if(mot_questions_filled) {
                        dispatch(updateValue("laufbahn_filled", true));
                    } else {
                        dispatch(updateValue("laufbahn_filled", false));
                    }

                    //saving sitequestions
                    let questions = reduxState.questions[3];
                    let questions_complete = true;
                    if(!!questions && questions.length > 0) {
                        questions.map(quest => {
                            let answer = reduxState["questionsAnswers." + quest.id];
                            if(!!answer) {
                                //checking if answer is already in an array
                                try {
                                    if(answer instanceof Array) {
                                        let addition = {values: answer}
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    } else {
                                        let answerPort = [];
                                        answerPort.push(answer);
                                        let addition = {
                                            values: answerPort
                                        };
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    }
                                } catch (e) {
                                    let answerPort = [];
                                    answerPort.push(answer);
                                    let addition = {
                                        values: answerPort
                                    };
                                    API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                }
                            } else {
                                if(quest.is_required) {
                                    questions_complete = false;
                                }
                            }
                        })
                    }

                    if(questions_complete) {
                        dispatch(updateValue("questions3_filled", true));
                    } else {
                        dispatch(updateValue("questions3_filled", false));
                    }

                    history.push(props.linkTo);

                    break;
                }
                case("/umfrage"): {

                    //saving sitequestions
                    let questions = reduxState.questions[4];
                    let questions_complete = true;
                    if(!!questions && questions.length > 0) {
                        questions.map(quest => {
                            let answer = reduxState["questionsAnswers." + quest.id];
                            if(!!answer) {
                                //checking if answer is already in an array
                                try {
                                    if(answer instanceof Array) {
                                        let addition = {values: answer}
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    } else {
                                        let answerPort = [];
                                        answerPort.push(answer);
                                        let addition = {
                                            values: answerPort
                                        };
                                        API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                    }
                                } catch (e) {
                                    let answerPort = [];
                                    answerPort.push(answer);
                                    let addition = {
                                        values: answerPort
                                    };
                                    API.post("applicant/" + reduxState.applicant_id + "/questions/" + quest.id + "/responses", addition);
                                }
                            } else {
                                if(quest.is_required) {
                                    questions_complete = false;
                                }
                            }
                        })
                    }

                    if(questions_complete) {
                        dispatch(updateValue("questions4_filled", true));
                    } else {
                        dispatch(updateValue("questions4_filled", false));
                    }

                    history.push(props.linkTo);

                    break;
                }
            }
        } catch (e) {
            setError(true);
            // consolelog(e);
        }
    }

    return (
        <div>
            {error && <SomethingWentWrongPopup/>}
            <Fab className={classes.card} color={"primary"} variant={"extended"} onClick={handleClick}>

                {loading ?
                    <div>
                        Speichern...
                        <CircularProgress variant={"indeterminate"} color={"#FFFFFF"} size={18}/>
                    </div> :
                    <div>
                        Weiter
                        <ArrowForwardIosIcon/>
                    </div>}
            </Fab>
        </div>

    )
};

ContinueButton.propTypes = {
    linkTo: PropTypes.string
};

export default ContinueButton;